import type { GetStaticPaths } from 'next';
import { ALLOW_PREVIEW_COPY } from '@peloton/app-config';
import type { Locale } from '@peloton/internationalize/models/locale';
import { toLocaleFromString } from '@peloton/internationalize/models/locale';
import { NextPageBuilderPage } from '@peloton/next/components/NextPageBuilderPage';
import { deployOnlyRoutes } from '@peloton/next/data/deployRoutes';
import { fetchTemplateSlugs, isTemplateSlug } from '@peloton/next/data/fetchTemplates';
import toGetStaticProps from '@peloton/next/data/toGetStaticProps';
import toPreviewSlugs from '@peloton/next/data/toPreviewSlugs';
import type { CatchAllPaths } from '@peloton/next/utils/getStaticPaths';
import { slugIncludesNoRender } from '@peloton/next/utils/ignoreRoutes';
import { getNextPageSlugs } from '@content/client/lib/pages';
import { splitPath, joinSegments } from '@content/client/lib/parseSlugs';
import { getClient } from '@content/client/pageBuilderClient';
import type { TypePage } from '@page-builder/lib/types';

const client = getClient(ALLOW_PREVIEW_COPY);

const pagePrefixesExcludedFromCatchAllRoute = [
  'instructors',
  'instructor-match/results',
  'next-callback',
  'next-authlogin',
];

export const isExcludedRoute = (slug: string = '') => {
  const excludedRoutes = ['/', 'sandbox', ...deployOnlyRoutes];
  const isProspectsRoute = /^\/?classes/.test(slug);
  return isProspectsRoute || excludedRoutes.includes(decodeURIComponent(slug));
};

export const generateSlugs = async (pages: TypePage[], locale: Locale) => {
  const previewSlugs = toPreviewSlugs();

  if (!pages.length) {
    return [];
  }

  return pages
    .map(page => page?.fields?.slug)
    .concat(previewSlugs)
    .filter(slug => {
      const isDefaultExcluded =
        !isExcludedRoute(slug) &&
        !slugIncludesNoRender(slug) &&
        !pagePrefixesExcludedFromCatchAllRoute.some(prefix => slug.startsWith(prefix));

      // Prerender any page that contains these keywords at build time
      const staticBuildRoutes = [
        'row',
        'guide',
        'tread',
        'tread-plus',
        'bike',
        'bike-plus',
        'deals',
        'shop',
        'app',
      ];

      return isDefaultExcluded && staticBuildRoutes.some(route => slug.includes(route));
    })
    .map(slug => {
      if (isTemplateSlug(slug)) {
        return fetchTemplateSlugs(slug, locale);
      }

      return {
        locale,
        params: {
          slugs: splitPath(slug),
        },
      };
    })
    .flat();
};

export const getStaticPaths: GetStaticPaths = async ({ locales = [] }) => {
  const paths: CatchAllPaths = [];

  for (const locale of locales) {
    const pages = await getNextPageSlugs(client, {
      locale,
    });
    const generated = await generateSlugs(pages, toLocaleFromString(locale));
    generated.forEach(page => {
      paths.push(page);
    });
  }

  return {
    paths,
    fallback: 'blocking',
  };
};

export const getStaticProps = toGetStaticProps({
  toPageSlug: params => joinSegments(params?.slugs),
  returnNotFound: true,
});

export default NextPageBuilderPage;
