import { PREVIEW_SLUGS } from '@peloton/app-config';
import { toLocaleFromHostname } from '@peloton/internationalize';

const toParsedPreviewSlug = (slug: string) => {
  const parts = slug.split(':');
  if (parts.length === 1) {
    return {
      slug,
    };
  }

  const [parsedLocale, parsedSlug] = parts;

  return {
    locale: parsedLocale,
    slug: parsedSlug,
  };
};

const toPreviewSlugs = (): string[] => {
  const locale = toLocaleFromHostname();

  const previewSlugs =
    PREVIEW_SLUGS !== undefined && PREVIEW_SLUGS !== '' ? PREVIEW_SLUGS.split(',') : [];

  const previewSlugsForLocale = previewSlugs
    .map(toParsedPreviewSlug)
    .filter(parsedSlug => !parsedSlug.locale || parsedSlug.locale === locale)
    .map(parsedSlug => parsedSlug.slug);

  return previewSlugsForLocale.map(slug => {
    if (slug.charAt(0) === '/') {
      return slug.substring(1, slug.length);
    }

    return slug;
  });
};

export default toPreviewSlugs;
